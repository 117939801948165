<svg
    width="90%"
    height="90%"
    viewBox="0 0 178 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M0.944092 122.214C1.20773 106.133 9.7084 88.7785 27.2663 88.1932C38.8856 87.8059 50.2646 90.6692 59.7389 82.7739C68.195 75.7272 74.0979 71.1182 85.674 71.1182C95.2604 71.1182 106.676 72.9815 115.48 68.8386C124.688 64.5055 128.632 58.5004 134.103 50.2153C138.457 43.6221 138.813 35.7703 142.791 29.1403C146.726 22.5822 152.385 20.2284 159.823 18.6458C167.619 16.9872 171.823 8.06551 175.135 1.44177"
        stroke="black"
        stroke-width="0.7"
        stroke-linecap="round"
    />
    <path
        d="M2.94409 125.214C3.20773 109.133 11.7084 91.7785 29.2663 91.1932C40.8856 90.8059 52.2646 93.6692 61.7389 85.7739C70.195 78.7272 76.0979 74.1182 87.674 74.1182C97.2604 74.1182 108.676 75.9815 117.48 71.8386C126.688 67.5055 130.632 61.5004 136.103 53.2153C140.457 46.6221 140.813 38.7703 144.791 32.1403C148.726 25.5822 154.385 23.2284 161.823 21.6458C169.619 19.9872 173.823 11.0655 177.135 4.44177"
        stroke="black"
        stroke-width="0.7"
        stroke-linecap="round"
    />
</svg>

<style>
    svg {
        padding: 0 1rem;
        overflow: visible;
    }
    svg:hover path {
        stroke: red;
        animation: shake 0.5s infinite;
    }
    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
</style>
