<style>
    h3 {
        margin: 0 auto;
        writing-mode: vertical-rl;
        position: fixed;
        top: 1rem;
    }
</style>

<div>
    <h3>Ulrik Antoniussen Halmøy</h3>
</div>
