<style>
    svg {
        padding: 0 1rem;
        overflow: visible;
    }
    svg:hover path {
        stroke: red;
        animation: shake 0.5s infinite;
    }
    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
</style>

<svg
    width="80%"
    height="80%"
    viewBox="0 0 132 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.38177 18.9749C8.79061 14.7138 9.90126 14.115 15.6174 14.115C19.148 14.115 19.9345 12.73 22.4592 10.2054C25.0584 7.60616 30.2434 10.2792 33.455 8.49493C38.5341 5.67321 41.4239 0.920044 47.8988 0.920044C52.561 0.920044 56.4267 1.85076 60.1164 4.80251C64.3022 8.15117 68.6736 12.5423 73.6643 14.6037C77.5947 16.2271 80.1095 16.5585 84.3615 16.5585C88.215 16.5585 91.7664 15.5811 95.5473 15.5811C98.099 15.5811 100.652 15.5545 103.204 15.5811C108.063 15.6317 111.064 17.6079 115.475 19.2735C119.308 20.7204 124.588 21.1014 126.526 25.1379C128.055 28.3246 126.982 33.1613 127.774 36.6496C128.462 39.675 128.996 43.0172 128.996 46.125C128.996 49.8255 128.917 53.436 128.508 57.1208C128.062 61.1295 127.153 62.3852 124.598 64.94C121.775 67.7625 120.939 71.8706 118.245 74.7141C115.595 77.5115 112.196 79.5539 109.665 82.4247C108.168 84.1233 107.137 86.1315 105.647 87.8004C104.741 88.8151 104.738 90.0154 103.475 90.8412C102.197 91.6771 100.546 91.665 99.1854 92.3073C97.2717 93.211 95.4687 94.244 93.5925 95.2124C89.0383 97.563 84.6004 97.1944 79.6102 97.1944C74.7346 97.1944 69.3539 97.3907 64.9491 94.968C60.5528 92.5501 55.9587 90.8142 51.8085 87.909C47.4912 84.887 42.4396 85.5628 37.5004 84.8682C32.9825 84.2329 27.7275 81.752 23.8167 79.4925C21.9846 78.4339 19.9641 77.5609 18.441 76.0716C16.5103 74.1838 14.8759 71.4287 12.7937 69.827C10.6916 68.21 8.95353 65.6317 7.79813 63.2567C6.49408 60.5762 6.25842 57.5576 5.73472 54.6773C5.41593 52.924 4.27127 51.5127 3.88851 49.7903C3.12396 46.3498 0.956299 43.6663 0.956299 40.0162C0.956299 36.9555 1.4548 34.4299 1.9337 31.4368C2.54806 27.597 2.87676 22.1061 5.38177 18.9749Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round" />
    <path
        d="M7.38177 20.9749C10.7906 16.7138 11.9013 16.115 17.6174 16.115C21.148 16.115 21.9345 14.73 24.4592 12.2054C27.0584 9.60616 32.2434 12.2792 35.455 10.4949C40.5341 7.67321 43.4239 2.92004 49.8988 2.92004C54.561 2.92004 58.4267 3.85076 62.1164 6.80251C66.3022 10.1512 70.6736 14.5423 75.6643 16.6037C79.5947 18.2271 82.1095 18.5585 86.3615 18.5585C90.215 18.5585 93.7664 17.5811 97.5473 17.5811C100.099 17.5811 102.652 17.5545 105.204 17.5811C110.063 17.6317 113.064 19.6079 117.475 21.2735C121.308 22.7204 126.588 23.1014 128.526 27.1379C130.055 30.3246 128.982 35.1613 129.774 38.6496C130.462 41.675 130.996 45.0172 130.996 48.125C130.996 51.8255 130.917 55.436 130.508 59.1208C130.062 63.1295 129.153 64.3852 126.598 66.94C123.775 69.7625 122.939 73.8706 120.245 76.7141C117.595 79.5115 114.196 81.5539 111.665 84.4247C110.168 86.1233 109.137 88.1315 107.647 89.8004C106.741 90.8151 106.738 92.0154 105.475 92.8412C104.197 93.6771 102.546 93.665 101.185 94.3073C99.2717 95.211 97.4687 96.244 95.5925 97.2124C91.0383 99.563 86.6004 99.1944 81.6102 99.1944C76.7346 99.1944 71.3539 99.3907 66.9491 96.968C62.5528 94.5501 57.9587 92.8142 53.8085 89.909C49.4912 86.887 44.4396 87.5628 39.5004 86.8682C34.9825 86.2329 29.7275 83.752 25.8167 81.4925C23.9846 80.4339 21.9641 79.5609 20.441 78.0716C18.5103 76.1838 16.8759 73.4287 14.7937 71.827C12.6916 70.21 10.9535 67.6317 9.79813 65.2567C8.49408 62.5762 8.25842 59.5576 7.73472 56.6773C7.41593 54.924 6.27127 53.5127 5.88851 51.7903C5.12396 48.3498 2.9563 45.6663 2.9563 42.0162C2.9563 38.9555 3.4548 36.4299 3.9337 33.4368C4.54806 29.597 4.87676 24.1061 7.38177 20.9749Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round" />
</svg>
