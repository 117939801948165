<style>
    svg {
        padding: 0 1rem;
        overflow: visible;
    }
    svg:hover path {
        stroke: red;
        animation: shake 0.5s infinite;
    }
    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
</style>

<svg
    width="90%"
    height="90%"
    viewBox="0 0 192 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M11.9665 58.8908C8.94155 54.0901 11.1715 47.8065 12.2473 42.7452C12.683 40.6955 12.576 39.1296 11.9811 37.1904C11.4252 35.3784 12.5538 32.9123 11.6834 31.1787C10.1697 28.1637 13.6876 21.9139 14.8862 19.2875C16.0078 16.8297 19.1837 15.6284 21.6248 14.9644C29.2316 12.8954 36.0923 9.0355 43.9725 7.84993C49.2825 7.05103 56.5576 9.83751 61.8165 11.0319C68.4413 12.5365 72.4211 15.2851 77.3956 19.4236C84.1441 25.0379 94.7528 27.2929 103.236 29.096L103.248 29.0987C113.57 31.2926 124.443 30.113 134.599 33.4796C139.195 35.0031 143.415 36.4788 148.214 37.4988C153.377 38.5963 158.587 39.5618 163.719 40.7945C168.654 41.98 173.873 44.0764 178.105 46.7785C181.783 49.1265 181.941 51.802 179.472 55.1717C177.855 57.3786 177.555 60.1069 177 62.7168C176.524 64.9582 177.652 66.4192 177.941 68.5758C178.481 72.5879 178.188 77.2421 179.197 81.1252C181.547 90.1699 173.06 94.2753 165.821 95.645C158.292 97.0697 151.513 101.002 143.923 101.858C138.885 102.426 134.384 102.853 129.341 101.781L114.39 98.6032C109.616 97.5884 105.652 95.1838 101.023 94.0256C96.81 92.9717 92.5837 91.6531 88.2506 90.732C85.3169 90.1085 82.5933 88.9508 79.6622 88.3278C76.4304 87.6408 74.2583 84.3376 70.7141 83.532C64.6025 82.1427 58.4481 80.9248 52.3175 79.6217C43.0304 77.6476 34.2954 74.6607 25.6776 70.776C20.3572 68.3777 15.0597 63.7999 11.9665 58.8908Z"
        stroke="black"
        stroke-width="1.19032"
        stroke-linecap="round"
        stroke-linejoin="round" />
    <path
        d="M12.9665 61.8908C9.94155 57.0901 12.1715 50.8065 13.2473 45.7452C13.683 43.6955 13.576 42.1296 12.9811 40.1904C12.4252 38.3784 13.5538 35.9123 12.6834 34.1787C11.1697 31.1637 14.6876 24.9139 15.8862 22.2875C17.0078 19.8297 20.1837 18.6284 22.6248 17.9644C30.2316 15.8954 37.0923 12.0355 44.9725 10.8499C50.2825 10.051 57.5576 12.8375 62.8165 14.0319C69.4413 15.5365 73.4211 18.2851 78.3956 22.4236C85.1441 28.0379 95.7528 30.2929 104.236 32.096L104.248 32.0987C114.57 34.2926 125.443 33.113 135.599 36.4796C140.195 38.0031 144.415 39.4788 149.214 40.4988C154.377 41.5963 159.587 42.5618 164.719 43.7945C169.654 44.98 174.873 47.0764 179.105 49.7785C182.783 52.1265 182.941 54.802 180.472 58.1717C178.855 60.3786 178.555 63.1069 178 65.7168C177.524 67.9582 178.652 69.4192 178.941 71.5758C179.481 75.5879 179.188 80.2421 180.197 84.1252C182.547 93.1699 174.06 97.2753 166.821 98.645C159.292 100.07 152.513 104.002 144.923 104.858C139.885 105.426 135.384 105.853 130.341 104.781L115.39 101.603C110.616 100.588 106.652 98.1838 102.023 97.0256C97.81 95.9717 93.5837 94.6531 89.2506 93.732C86.3169 93.1085 83.5933 91.9508 80.6622 91.3278C77.4304 90.6408 75.2583 87.3376 71.7141 86.532C65.6025 85.1427 59.4481 83.9248 53.3175 82.6217C44.0304 80.6476 35.2954 77.6607 26.6776 73.776C21.3572 71.3777 16.0597 66.7999 12.9665 61.8908Z"
        stroke="black"
        stroke-width="1.19032"
        stroke-linecap="round"
        stroke-linejoin="round" />
</svg>
