<style>
    svg {
        padding: 0 1rem;
        overflow: visible;
    }
    svg:hover path {
        stroke: red;
        animation: shake 0.5s infinite;
    }
    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
</style>

<svg
    width="90%"
    height="90%"
    viewBox="0 0 184 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M160.544 57.8397C167.03 52.5821 171.986 50.8713 176.346 44.2277C177.728 40.7699 176.677 38.2041 175.776 34.842C174.901 31.5776 173.225 30.3991 170.736 28.1312C168.877 26.436 165.811 25.2582 163.728 23.618C161.576 21.9229 158.571 20.25 155.668 20.2859C152.666 20.3229 149.773 18.9447 146.677 19.2587C145.115 19.4171 143.456 19.7175 141.882 19.6442C138.43 19.4836 135.108 19.1867 131.686 19.2289C124.171 19.3216 116.274 18.5643 108.801 19.58C105.423 20.0391 101.058 21.3045 97.846 22.5154C94.2689 23.864 90.95 25.6634 87.4253 27.0418C85.8445 27.6601 82.77 28.668 81.7298 29.981C80.3986 31.6616 76.193 31.2358 74.3163 32.2886C72.853 33.1096 71.4251 33.6093 69.5802 34.1037C63.5131 35.7293 57.9424 38.3782 51.8237 40.0177C47.5663 41.1584 43.1761 43.6527 39.2625 45.6958C36.4818 47.1475 33.249 49.0208 31.3925 51.2731C29.6316 53.4094 26.3622 56.1293 24.0132 57.8751C20.7428 60.3056 18.2399 63.423 15.2171 66.045C13.6416 67.4115 8.67767 71.2046 9.12691 73.4256C9.74424 76.4777 11.4172 78.5145 12.5045 81.1919C13.6577 84.0315 16.0779 87.1903 19.051 88.6872C20.5857 89.4598 21.9625 90.2084 23.5474 90.9509C25.4055 91.8214 27.7628 90.9233 29.5694 91.6497C32.4035 92.7891 37.0627 92.1461 40.227 92.7121C45.208 93.6031 50.3115 93.8169 55.3596 93.539C59.0102 93.338 62.0421 93.9323 65.718 92.9473L78.8909 89.4177C85.1846 87.7313 91.6081 86.2944 97.6642 83.9378C103.268 81.7569 109.202 81.2958 115.049 79.7291C119.547 78.5239 123.355 76.9601 127.565 75.0909C131.786 73.2168 136.317 71.7819 140.358 69.6074C143.94 67.6803 148.03 65.505 151.846 64.0886C155.325 62.7974 157.806 60.0592 160.544 57.8397Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round" />
    <path
        d="M159.897 55.4226C166.382 50.165 171.338 48.4542 175.698 41.8106C177.08 38.3527 176.029 35.787 175.128 32.4249C174.254 29.1605 172.577 27.9819 170.089 25.7141C168.229 24.0189 165.164 22.8411 163.081 21.2009C160.928 19.5058 157.923 17.8329 155.02 17.8687C152.019 17.9058 149.125 16.5275 146.029 16.8416C144.467 16.9999 142.808 17.3004 141.234 17.2271C137.782 17.0665 134.461 16.7696 131.038 16.8118C123.523 16.9045 115.626 16.1472 108.153 17.1629C104.775 17.622 100.41 18.8874 97.1983 20.0983C93.6212 21.4469 90.3023 23.2463 86.7776 24.6247C85.1968 25.243 82.1223 26.2509 81.0821 27.5639C79.7509 29.2444 75.5452 28.8187 73.6686 29.8715C72.2053 30.6925 70.7774 31.1922 68.9325 31.6865C62.8654 33.3122 57.2947 35.9611 51.176 37.6006C46.9186 38.7413 42.5284 41.2356 38.6148 43.2787C35.8341 44.7304 32.6013 46.6037 30.7448 48.856C28.9839 50.9923 25.7145 53.7122 23.3655 55.458C20.0951 57.8884 17.5922 61.0059 14.5694 63.6279C12.9939 64.9944 8.02996 68.7875 8.4792 71.0085C9.09654 74.0606 10.7694 76.0973 11.8568 78.7748C13.01 81.6144 15.4301 84.7732 18.4033 86.2701C19.938 87.0427 21.3148 87.7913 22.8997 88.5338C24.7578 89.4043 27.1151 88.5062 28.9217 89.2325C31.7558 90.372 36.415 89.729 39.5793 90.295C44.5602 91.1859 49.6638 91.3997 54.7119 91.1219C58.3625 90.9209 61.3944 91.5152 65.0703 90.5302L78.2432 87.0005C84.5369 85.3142 90.9604 83.8773 97.0165 81.5207C102.621 79.3398 108.555 78.8786 114.402 77.312C118.899 76.1068 122.708 74.543 126.917 72.6738C131.138 70.7996 135.669 69.3647 139.711 67.1903C143.293 65.2632 147.382 63.0879 151.198 61.6715C154.677 60.3803 157.159 57.6421 159.897 55.4226Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round" />
</svg>
