<style>
    svg {
        padding: 0 1rem;
        overflow: visible;
    }
    svg:hover path {
        stroke: red;
        animation: shake 0.5s infinite;
    }
    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
</style>

<svg
    width="80%"
    height="80%"
    viewBox="0 0 148 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M75.2519 4.84797C64.1068 23.4232 77.9445 31.0656 61.0793 52.2544C61.0793 70.0912 30.8788 74.495 25.2179 71.979C-16.1479 70.2312 9.05854 83.8138 17.393 83.8138C22.3025 83.8138 30.2091 85.3499 34.7798 83.5216C41.382 80.8807 50.7211 78.8833 57.7919 77.312C66.1538 75.4538 61.2372 87.2728 58.4494 90.3887C51.7611 97.8638 49.782 107.543 45.9571 115.958C43.7841 120.738 44.8392 131.76 46.9068 136.413C49.7048 142.708 56.7885 137.195 60.273 134.483L60.4218 134.367C67.8286 128.606 71.3785 116.975 75.2519 108.798C77.9392 103.125 82.119 97.4254 82.119 91.0462C82.119 83.4456 88.7991 86.7393 92.3466 90.6809C96.0182 94.7605 100.418 101.956 103.159 106.753C106.377 112.386 112.987 114.682 117.623 119.318C120.97 122.664 123.718 126.824 127.486 129.838C131.012 132.659 127.441 145.618 132.745 145.618C141.703 145.618 143.923 145.925 143.923 136.413C143.923 127.916 141.996 122.215 138.663 114.716C135.61 107.846 127.404 103.261 123.906 96.9635C121.368 92.3952 115.08 85.7612 110.391 83.1563C105.145 80.2418 97.9648 78.9308 102.136 71.979C107.862 62.4349 120.412 63.2833 130.042 61.5323C138.636 59.9698 146.553 61.1014 146.553 50.9394C146.553 46.7277 147.606 41.1494 146.48 37.2053C146.04 35.6681 139.351 36.4747 137.932 36.4747C127.331 36.4747 115.486 35.2786 105.789 40.1274C99.4769 43.2833 91.8631 46.4552 86.7214 51.5969C84.0795 54.2388 82.119 57.9032 82.119 51.9622C82.119 48.1147 87.5576 7.46985 83.188 5.43069C78.7698 -0.249919 77.5856 0.958423 75.2519 4.84797Z"
        stroke="black"
        stroke-width="0.582615"
        stroke-linecap="round"
        stroke-linejoin="round" />
    <path
        d="M73.504 4.26539C62.3589 22.8406 76.1966 30.4831 59.3315 51.6718C59.3315 69.5086 29.131 73.9124 23.47 71.3965C-17.8957 69.6486 7.31067 83.2312 15.6451 83.2312C20.5547 83.2312 28.4612 84.7673 33.032 82.939C39.6341 80.2981 48.9732 78.3007 56.044 76.7294C64.4059 74.8712 59.4894 86.6902 56.7015 89.8061C50.0132 97.2813 48.0342 106.96 44.2093 115.375C42.0363 120.156 43.0913 131.178 45.159 135.83C47.957 142.126 55.0407 136.612 58.5251 133.9L58.674 133.785C66.0808 128.024 69.6307 116.393 73.504 108.216C76.1914 102.542 80.3711 96.8428 80.3711 90.4636C80.3711 82.863 87.0513 86.1567 90.5987 90.0983C94.2704 94.1779 98.6699 101.374 101.411 106.17C104.629 111.803 111.239 114.099 115.875 118.736C119.222 122.082 121.97 126.241 125.738 129.255C129.264 132.076 125.693 145.035 130.998 145.035C139.955 145.035 142.175 145.342 142.175 135.83C142.175 127.334 140.248 121.632 136.915 114.133C133.862 107.263 125.657 102.678 122.158 96.381C119.62 91.8126 113.332 85.1786 108.643 82.5737C103.397 79.6592 96.2169 78.3482 100.388 71.3965C106.114 61.8523 118.664 62.7007 128.295 60.9497C136.888 59.3872 144.805 60.5188 144.805 50.3569C144.805 46.1451 145.859 40.5668 144.732 36.6227C144.292 35.0855 137.603 35.8922 136.184 35.8922C125.583 35.8922 113.738 34.696 104.041 39.5449C97.729 42.7007 90.1152 45.8726 84.9735 51.0144C82.3316 53.6562 80.3711 57.3206 80.3711 51.3796C80.3711 47.5321 85.8098 6.88727 81.4402 4.84811C77.0219 -0.8325 75.8378 0.375842 73.504 4.26539Z"
        stroke="black"
        stroke-width="0.582615"
        stroke-linecap="round"
        stroke-linejoin="round" />
</svg>
